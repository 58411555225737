<template>
  <div class="last-articles-v2-block">
    <div class="container">
      <div class="last-articles-v2-block__content">
        <h2 v-if="title" v-html="title" class="last-articles-v2-block__title"/>
        <div v-if="caption" v-html="caption" class="last-articles-v2-block__caption"/>
        <div class="last-articles-v2-block__items">
          <ArticleCard
            v-for="(item) in articles"
            :key="`article-card-${ item.id }`"
            :article="item"
          />
        </div>
        <div class="last-articles-v2-block__link">
          <nuxt-link
            :to="localePath('/blog')"
            class="btn btn-primary"
          >{{ $t('blog.go_to_blog') }}</nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import { ArticleItem, type IArticleItem } from '#sitis/internal/controllers/articles/models/ArticleItem';
import ArticleCard from '~/components/blog/ArticleCardV2.vue';

const props = defineProps<IBlockDefaultProps>();
const localePath = useLocalePath();

const title: ComputedRef<string> = computed(() => {
  return props?.values?.title
});
const caption: ComputedRef<string> = computed(() => {
  return props?.values?.caption
});
const articles: ComputedRef<IArticleItem[]> = computed(() => {
  return [...(props.extras?.articles || [])]
    .map((t: any) => {
      return new ArticleItem(t)
    })
});

onMounted(() => [
  console.log('asdasdasd: ', props)
]);
</script>

<style lang="scss" scoped>
.last-articles-v2-block {
  margin: 50px 0;
}
.last-articles-v2-block__title {
  font-size: 48px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.03em;
  color: hsla(var(--main-section-tx)/1);
}
.last-articles-v2-block__caption {
  margin-top: 8px;

  font-size: 22px;
  line-height: 1.4;
  font-weight: 500;
  color: hsla(var(--main-section-tx)/0.6);
}
.last-articles-v2-block__items {
  display: flex;
  flex-wrap: wrap;
  padding-top: 56px;
  margin-top: -56px;
  margin-left: -12px;

  & > * {
    width: calc(50% - 12px);
    margin-top: 56px;
    margin-left: 12px;
  }
}
.last-articles-v2-block__link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

@media (max-width: 1023px) {
  .last-articles-v2-block__title {
    font-size: 32px;
  }
  .last-articles-v2-block__caption {
    margin-top: 10px;
    font-size: 18px;
  }
  .last-articles-v2-block__items {
    padding-top: 48px;
  }
}
@media (max-width: 639px) {
  .last-articles-v2-block__items {
    flex-direction: column;
    margin-left: 0;

    & > * {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
